<template>
  <v-app>

    <v-app-bar
      app
      color="#2e3141"
      dark
      hide-on-scroll
    >
      <v-spacer></v-spacer>
      <div class="navitems">
        <router-link to="/home/#portfolio" v-smooth-scroll="{duration: 700, offset:-170}">Portfolio</router-link>
        <router-link to="/home/#contact" v-smooth-scroll="{duration: 700, offset:-50}">Kapcsolat</router-link>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-content>
      <v-container fluid>
        <router-view></router-view>

        <transition name="fade">
          <v-btn v-if="showButton" class="scrollUpButton" fab @click="navigateToTop">
            <v-icon>mdi-menu-up-outline</v-icon>
          </v-btn>
        </transition>

      </v-container>
    </v-content>

    <v-footer
      app
      color="#2e3141"
      dark
      class="appfooter"
      absolute
    >
      <v-row no-gutters>
        <v-col cols="12" class="text-center my-1">
          IldikoZsigmond {{new Date().getFullYear()}}
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col cols="7" sm="4" md="2" xl="1" class="text-center">
          <router-link :to="{name: 'impresszum'}">Impresszum</router-link>
          <a href="https://www.pexels.com/" target="_blank">Képek: Pexels</a>
          <a href="https://html5up.net/" target="_blank">Design ötlet: HTML5UP</a>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  methods: {
    showScrollButton(){
      window.onscroll = () => {
        if(document.documentElement.scrollTop < 230){
          this.showButton = false;
        }
        else{
          this.showButton = true;
        }
      }
    },
    navigateToTop(){
      this.$vuetify.goTo(0, {duration:900, easing: 'easeInOutCubic'})
      if(this.$route.hash){
        this.$router.push('/')
      }
    },
  },
  data(){
    return{
      showButton: false,
    }
  },
  mounted(){
    this.showScrollButton();
  }
};
</script>

<style lang="scss">
@import './styles/global.scss';

.container.container{
  padding: 0;
}
.v-application .navitems a{
  display: inline-block;
  padding: 0 5px;
  margin: 0 15px;
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  color: #fff;

  &:hover{
    color: #ccc;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.scrollUpButton{
  position: fixed !important;
  bottom: 10px;
  right: 15px;


  &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
    background-color: rgba(255,255,255,0.3);
    z-index: 10;
  }
}
.v-footer.appfooter{
  display: block;
}
.appfooter a{
  display: block;
  margin: 0 15px;
  font-size: 12px;
}
</style>
