<template lang="html">
  <div class="portfolio--item" :class="itemNumber">
      <v-row justify="start" justify-sm="center" align="center" :class="{left:imageOnLeft, right: !imageOnLeft}">
        <v-col cols="11" sm="6" md="6" :offset="imageOnLeft ? 1 : 0">
          <div class="portfolio--title">
            <slot name="portfolioTitle"></slot>
          </div>
          <div class="portfolio--description">
            <slot name="portfolioDescription"></slot>
          </div>
          <div class="portfolio--action">
            <a :href="pageLink" target="_blank">
              <v-icon large>
                mdi-play-circle-outline
              </v-icon>
              Megnézem!
            </a>
          </div>
        </v-col>
        <v-col cols="7" :offset="imageOnLeft ? 1 : 4" :offset-sm="imageOnLeft ? 0 : 1" sm="3" md="2" order="first" :order-sm="imageOnLeft ? 'first':'last'">
          <div class="portfolio--image">
            <img :src="imageSrc" />
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  props:{
    imageOnLeft: {
      default: false,
      type: Boolean,
    },
    pageLink: {
      required: true,
      type: String
    },
    imageSrc: {
      required: true,
      type: String
    },
    repositorySrc:{
      required: false
    },
    itemNumber:{
      type: String,
      default: 'first',
    }
  }
}
</script>

<style lang="scss" scoped>

.portfolio--item {
  margin: 95px 0;
  position: relative;

  @media(max-width: 1000px){
      margin: 75px 0;
  }

  @media(max-width:600px){
      margin: 35px 0;
  }

  .right:before,
  .right:after,
  .left:before,
  .left:after{
    position: absolute;
    content: '';
    display: block;
    height: 100px;
    width: 100%;

    @media(max-width: 1000px){
      height: 80px;
    }

    @media(max-width:600px){
      height: 40px;
    }

  }

  .right:before,
  .left:before{
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
    top: -98px;
    left:0;

    @media(max-width: 1000px){
      top: -78px;
    }

    @media(max-width:600px){
      top: -38px;
    }

  }

  .right:after,
  .left:after{
    clip-path: polygon(0 0, 100% 0%, 100% 100%);
    bottom: -98px;

    @media(max-width: 1000px){
      bottom: -78px;
    }

    @media(max-width:600px){
      bottom: -38px;
    }
  }
}

.portfolio--item .left:before{
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}
.portfolio--item .left:after{
  clip-path: polygon(0 0%, 100% 0, 0 100%);
}

.portfolio--item .right,
.portfolio--item .right:before,
.portfolio--item .right:after{
  text-align: right;
}

.portfolio--item .left,
.portfolio--item .left:before,
.portfolio--item .left:after{
  text-align: left;
}



.portfolio--item .left{
    .portfolio--action{
      justify-content: flex-start;
    }
}

.portfolio--item.first >.row,
.portfolio--item.first >.row:before,
.portfolio--item.first >.row:after{
    background-color: $firstcolor;
}

.portfolio--item.second >.row,
.portfolio--item.second >.row:before,
.portfolio--item.second >.row:after{
    background-color: $secondcolor;
}

.portfolio--item.third >.row,
.portfolio--item.third >.row:before,
.portfolio--item.third >.row:after{
    background-color: $thirdcolor;
}

.portfolio--title{
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: solid 2px rgba(255, 255, 255, 0.125);
  font-size: 1.2rem;
  color: #ffffff;
  text-transform: uppercase;
}

.portfolio--description{
  margin: 0 0 2em 0;
}

.portfolio--image img{
  max-width: 100%;
  border-radius: 50%;
}

.portfolio--action{
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;


  .mdi-play-circle-outline{
    margin-right: 10px;
    color: #fff;
  }

  a{
    color: white;
    &:hover{
      color: silver;
      transition: color 0.3s ease;
    }
  }
}


</style>
