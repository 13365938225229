<template>
  <div class="home">
    <section id="banner">
      <h1>Zsigmond Ildikó</h1>
      <p>Frontend fejlesztő</p>
    </section>

    <section id="portfolio">

      <v-row justify="center">
        <v-col cols="11" sm="8">
          <h2 class="sectiontitle">
            Portfolio
          </h2>
        </v-col>
      </v-row>

      <PortfolioRow
        pageLink="https://pecel1gyerek.hu"
        :imageSrc="require('@/assets/images/maciolvas.jpg')"
      >
        <template v-slot:portfolioTitle>
          Péceli 1. számú gyermekkörzet oldala
        </template>
        <template v-slot:portfolioDescription>
          Python/Django backenddel rendelkező, Vue, Vuetify segítségével
          készített oldal.
        </template>
      </PortfolioRow>

      <PortfolioRow
        imageOnLeft
        itemNumber='second'
        pageLink="https://soroban-practice.firebaseapp.com/"
        :imageSrc="require('@/assets/images/addition.jpg')"
      >
        <template v-slot:portfolioTitle>
          Soroban gyakorló
        </template>
        <template v-slot:portfolioDescription>
          Vue, Vuetify segítségével készített statikus oldal, animált sorobannal.
        </template>
      </PortfolioRow>

      <PortfolioRow
        itemNumber="third"
        pageLink="https://shelter-basic.firebaseapp.com/"
        :imageSrc="require('@/assets/images/dog.jpg')"
      >
        <template v-slot:portfolioTitle>
          Egy elképzelt állatmenhely oldala
        </template>
        <template v-slot:portfolioDescription>
          Python/Django backenddel rendelkező, Vue, Vuetify segítségével
          készített oldal.
        </template>
      </PortfolioRow>

    </section>

    <section id="contact">
      <v-dialog
        v-model="error"
        max-width="290"
      >
        <v-card color="#4c5c96">
          <div class="dialogtitle">Az üzenet elküldése nem sikerült!</div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              color="white"
              @click="error = false"
            >
              Kilépés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showDialog"
        max-width="290"
      >
        <v-card color="#4c5c96">
          <div class="dialogtitle">Köszönöm üzenetét!</div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              color="white"
              @click="showDialog = false"
            >
              Kilépés
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row justify="center">
        <v-col cols="11" sm="8">
          <h2 class="sectiontitle">
            Kapcsolat
          </h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" sm="6">
          <v-form ref="form">
            <label class="contactlabel">NÉV</label>
            <v-text-field
              background-color="rgba(255, 255, 255, 0.025)"
              dark
              v-model="name"
              solo
              class="forminput"
              counter="50"
              autocomplete="off"
              :rules="[rules.required, rules.name]"
             ></v-text-field>

             <label class="contactlabel">EMAIL-CÍM</label>
             <v-text-field
               dark
               background-color="rgba(255, 255, 255, 0.025)"
               class="forminput"
              v-model="email"
              solo
              type="email"
              counter="50"
              autocomplete="off"
              :rules="[rules.required, rules.email]"
              ></v-text-field>

            <label class="contactlabel">ÜZENET</label>
            <v-textarea
              dark
              background-color="rgba(255, 255, 255, 0.025)"
              solo
              v-model="message"
              class="forminput"
              counter="500"
              :rules="[rules.required,rules.textareaLength]"
            ></v-textarea>

            <v-textarea
              v-model="rCheck2"
              :class="{botCheck: true}"
            ></v-textarea>

            <v-checkbox
              v-model="rCheck"
              required
              :rules="[v => !!v || 'Ki kell pipálnia az üzenet elküldéséhez!']"
              dark
              @click="submitTime=new Date();rCheck=true"
              label="Nem vagyok robot"
            ></v-checkbox>

            <v-checkbox
              required
              :rules="[v => !!v || 'El kell fogadnia az üzenet elküldéséhez!']"
              dark
              v-model="acceptTerms"
              label="Kijelentem, hogy a megadott email-cím a saját tulajdonomban van, valamint elfogadom, hogy kizárólag az üzenethez kapcsolódó kapcsolattartás céljára azt rögzítsék."
            ></v-checkbox>

            <v-btn
              @click="formSubmit"
              :disabled="messageSent"
              class="mt-5" outlined dark>ELKÜLD</v-btn>

          </v-form>
        </v-col>
      </v-row>


    </section>
  </div>
</template>

<script>
import axios from 'axios';
import PortfolioRow from '@/components/Layout/PortfolioRow.vue'

export default {
  data(){
    return{
      name: '',
      email: '',
      message: '',
      messageSent: false,
      error: false,
      showDialog: false,
      showError: false,
      acceptTerms: false,
      notRobot: false,
      startTime: null,
      submitTime: null,
      rCheck: false,
      rCheck2: null,
      rules: {
        required(v){
            if (v.length>0) {
              return true
            }else return 'Megadása kötelező'
        },
        email(v){
          return /.+@.+\..+/.test(v) || 'Az email-cím nem megfelelő'
        },
        name(v){
          if (v.length>5 && v.length<51) {
            return true
          }else return 'A név minimum 6, max 50 karakter kell, hogy legyen'
        },
        textareaLength(v){
          if (v.length>20 && v.length<501) {
            return true
          }else return 'A szöveg minimum 21, max 500 karakter kell, hogy legyen'
        }
      }
    }
  },
  methods:{
    formSubmit(){
      if (this.$refs.form.validate()&&this.acceptTerms&&this.notRobot) {
        this.messageSent = true;
        let form = { "data": {"name": this.name, "email": this.email, "message": this.message}}
        axios.post('https://sqadlyeemuhsjbd.form.io/zsiportfolio/submission/', form)
        .then(res=>{
          this.$vuetify.goTo(0)
          this.showDialog = true;
          this.error = false
          this.acceptTerms = false
          this.showError = false
          this.$refs.form.reset()
          this.email=''
          this.name=''
          this.message=''
          this.notRobot = false
          this.rCheck = false
        })
        .catch(err=>{
          this.error = true
          this.messageSent = false;
        })
      }
    },
  },
  mounted(){
    this.$vuetify.goTo(0);
    this.startTime = new Date()
    if (this.$route.hash) {
      this.$router.push('/')
    }
  },
  watch:{
    submitTime(){
      if (this.submitTime-this.startTime>10000&&this.rCheck2==null) {
        this.notRobot = true
      }
    }
  },
  components:{
    PortfolioRow,
  }
};
</script>

<style lang="scss" scoped>

#banner{
    padding: 7rem 0 8.25rem 0;
    margin-bottom: -3rem;

  h1{
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
    margin-bottom: 1.2rem;
    padding-bottom: 0.8rem;
  }

  p{
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 0.1rem;
    line-height: 2;
    text-transform: uppercase;
    text-align: center;
  }
}

.sectiontitle{
  padding: 12px;
}

#portfolio{
  position: relative;

  &>.row:first-child{
    position: absolute;
    top: -100px;
    width:100%;
  }

  @media(max-width:960px){
    margin-top: 150px;
  }

}

.sectiontitle{
  text-align: left;
  letter-spacing: 0.6rem;
  padding-bottom: 1rem;
}

#contact{

  padding-bottom: 50px;

  .sectiontitle{
    border-bottom: solid 2px rgba(255, 255, 255, 0.125);
  }

  .contactlabel{
    display: inline-block;
    margin-bottom: 6px;
  }

  .forminput{
    border-radius: 10px;
  }
  .botCheck{
    display: none;
  }

}

.dialogtitle{
  padding: 1.5rem 1rem 1rem 1rem;
  font-size: 1.2rem;
  color: #ffffff;
  text-transform: uppercase;
}



</style>
